import { useRouter } from 'next/compat/router';
import { type PropsWithChildren } from 'react';

import { H4 } from '~/ui/components/text';

export function CreditsWrapper({ children }: PropsWithChildren) {
  const router = useRouter();
  const isDetailPage = /changelog\/20\d\d\/.*/.test(router?.pathname ?? '');

  if (!isDetailPage) {
    return null;
  }

  return (
    <div className="not-prose mt-12">
      <div className="mb-4 border-b border-default pb-2">
        <H4 className="m-0">Credits</H4>
      </div>
      <div className="flex flex-col gap-3">{children}</div>
    </div>
  );
}
